@import "../../../globals/styles/_media-queries.scss";@import "../../../globals/styles/_variables.scss";.mainContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000040;
  backdrop-filter: blur(2px);

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Barlow', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: $color-text;
  text-align: initial;

  * {
    box-sizing: border-box;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.processIA {
  max-width: $width-base;
  width: 100%;
  max-height: $height-base;
  margin-inline: 4px;
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  background-color: $color-background;

  &.is-transition {
    pointer-events: none;
  }
  &.is-prev {
    flex-direction: row-reverse;
  }

  @include large-screen {
    max-height: $height-large-screen;
    margin-inline: auto;
  }
}

.closeIconContainer {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 18px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $color-background-light;
  z-index: 25;
  cursor: pointer;

  @include large-screen {
    top: 22px;
    right: 32px;
  }
}

.closeIcon {
  width: 100%;
  height: 100%;
}

.processIA__step {
  flex-shrink: 0;
  width: 100%;
  .processIA.is-transition & {
    transition: 0.3s;
    transform: translateX(-100%);
  }
  .processIA.is-transition.is-prev & {
    transition: 0.3s;
    transform: translateX(100%);
  }
}
