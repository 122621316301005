@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.cartCardContainer {
  height: 88px;
  max-width: 360px;
  width: 100%;
}

.cartCard {
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
  transition: 0.2s;
  border: 2px solid var(--color-sa-action-light);
  border-radius: 8px;
}

.cartCard--fullWidth {
  max-width: 386px;
  width: 100%;
  margin: 0 auto;
}

.cartCard__body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  padding: 16px 16px 16px 8px;
  background-color: $white;
  border-radius: 8px;
}

.cartCard__visual {
  width: 50px;
  flex-shrink: 0;
}

.cartCard__visual-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cartCard__content {
  display: flex;
  flex-direction: column;
  margin-inline: 16px;
  gap: 4px;
}

.cartCard__title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 20px;
  color: $text-main;
}

.cartCard__brand {
  font-size: 12px;
  line-height: 14px;
  color: $text-light;
}

.cartCard__priceContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  margin-left: auto;
}

.cartCard__finalPrice {
  font-family: 'Mulish', sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: $text-main;
}

.cartCard__originalPrice {
  font-family: 'Mulish', sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: $text-light;
  text-decoration: line-through;
}
