@import "../../../../globals/styles/_media-queries.scss";@import "../../../../globals/styles/_variables.scss";.picturePreparationStep {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  max-height: $height-base;

  @include large-screen {
    max-height: $height-large-screen;
  }
}

.picturePreparationStep__visual {
  position: relative;
  display: flex;
  justify-content: center;
  height: 42dvw;
  max-height: 100px;
  width: 100%;
  overflow: hidden;

  @include large-screen {
    max-height: 129px;
  }
}

.picturePreparationStep__visual__image {
  width: 100%;
  object-fit: cover;
}

.picturePreparationStep__content {
  height: 100%;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include large-screen {
    padding: 24px;
  }
}

.picturePreparationStep__warning {
  display: none;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  @include large-screen {
    display: flex;
    gap: 8px;
  }
}

.picturePreparationStep__warningText {
  font-size: 12px;
  line-height: 14px;
}

.picturePreparationStep__title {
  margin-bottom: 12px;
}

.picturePreparationStep__description {
  margin: 12px 0 auto 0;
}

.picturePreparationStep__tipSection {
  margin-top: 12px;
}

.picturePreparationStep__tip {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  span {
    color: var(--color-sa-info);
    font-weight: bold;
  }
}

.picturePreparationStep__tipIcon {
  margin-right: 16px;
  path {
    fill: var(--color-sa-info);
  }
}

.picturePreparationStep__dataPrivacy {
  display: flex;
  gap: 8px;
  padding: 8px;
  background-color: white;
  border-radius: 8px;
}

.picturePreparationStep__dataPrivacy__title {
  font-size: 12px;
  margin-bottom: 4px;
  color: $color-title;
  font-weight: 700;
}

.picturePreparationStep__dataPrivacy__text {
  font-size: 12px;
  line-height: 15px;
}

.picturePreparationStep__button {
  margin-bottom: 8px;
}

.picturePreparationStep__fileInput {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.picturePreparationStep__downloadPhotoButton {
  display: block;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  color: var(--color-sa-action);
}
