@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.recommendationSubroutine {
  padding: 0;

  &:not(.recommendationSubroutine--active) {
    padding-bottom: 16px;
  }
}

.recommendationSubroutine__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .recommendationSubroutine--active & {
    cursor: auto;
  }
}

.recommendationSubroutine__title {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;

  &__number {
    min-width: 32px;
    min-height: 32px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    background-color: var(--color-sa-info);
    border-radius: 50%;
  }
}

.recommendationSubroutine__body {
  width: 100%;
  transition: 0.2s;
  overflow: hidden;
  height: 1px;

  .recommendationSubroutine--active & {
    max-height: fit-content;
    height: auto;
  }
}

.recommendationSubroutine__slider {
  width: 100%;
  padding: 20px 0 25px;
  max-width: 500px;

  :global(.slick-track) {
    display: flex;
  }

  :global(.slick-slide) {
    height: inherit;
    > div {
      height: 100%;
    }
  }
}
