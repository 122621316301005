@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.ageCard {
  &__container {
    height: 100%;
    width: $width-diagnosis-card-mobile;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 8px;
    background-color: $white;
    border: 1px solid var(--color-sa-action-light);
    border-radius: 32px;

    @include large-screen {
      width: $width-diagnosis-card-large-screen;
    }
  }

  &__summaryContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 67px;
    width: 100%;
    padding: 16px 24px;
  }

  &__name {
    font-size: 14px;
  }

  &__visual {
    width: 100%;
    height: 320px;
  }

  &__visual__image__age {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(55deg, var(--color-sa-info) 23.39%, rgba(217, 5, 158, 0) 128.49%);

    color: white;
    font-size: 128px;
  }

  &__text {
    padding: 16px;
    font-size: 14px;
    line-height: 17px;
  }
}
