@import "../../../../globals/styles/_media-queries.scss";@import "../../../../globals/styles/_variables.scss";.diagnosisStep {
  width: 100%;
  max-width: $width-base;
  height: 100dvh;
  max-height: $height-base;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include large-screen {
    max-height: $height-large-screen;
  }
}

.diagnosisStep__main {
  height: $height-base - $height-diagnosis-step-footer-mobile;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-inline: 16px;
  overflow-y: auto;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
  }

  @include large-screen {
    height: $height-large-screen - $height-diagnosis-step-footer-large-screen;
  }
}

.diagnosisStep__title {
  margin-top: 20px;
  align-self: flex-start;
  font-size: 18px;
  line-height: 22px;

  @include large-screen {
    margin-top: 24px;
  }
}

.diagnosisStep__scoreContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  width: 100%;
  padding-bottom: 12px;

  @include large-screen {
    padding-bottom: 16px;
  }

  :global(.slick-list) {
    height: 100%;
    overflow: visible;
  }

  :global(.slick-track) {
    display: flex;
    height: 100%;
  }

  :global(.slick-slide) {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    width: 346px !important;
    transition: opacity 0.3s ease;

    @include large-screen {
      width: 396px !important;
    }

    > div {
      width: $width-diagnosis-card-mobile;
      height: 100%;

      @include large-screen {
        width: $width-diagnosis-card-large-screen;
      }

      > div {
        height: 100%;
      }
    }
  }

  :global(.slick-dots li) {
    height: unset;
    width: unset;
    margin: 0 3px 0 0;
  }

  :global(.slick-dots button) {
    height: 8px;
    width: 8px;
    padding: 0;
    border-radius: 4px;
    background-color: $grey;
  }

  :global(.slick-dots .slick-active button) {
    width: 16px;
    background-color: var(--color-sa-action);
  }

  :global(.slick-dots),
  :global(.slick-dots .slick-active) {
    button::before {
      color: var(--color-sa-action);
      content: none;
    }
  }
}

.diagnosisStep__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  gap: 16px;
  height: $height-diagnosis-step-footer-mobile;
  width: 100%;
  padding: 8px 16px 16px 16px;

  @include large-screen {
    height: $height-diagnosis-step-footer-large-screen;
  }
}

.diagnosisStep__button {
  padding-inline: 48px;
}

.diagnosisStep__button__mobile {
  width: fit-content;
  margin: 8px auto 0 auto;
  padding: 0;
  border: none;
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-sa-action);
  background-color: transparent;
  cursor: pointer;
}

.diagnosisStep__loading {
  flex-grow: 1;
  width: 100%;
}
