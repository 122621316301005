@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.personnalisationStep {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.personnalisationStep__imageContainer {
  display: flex;
  justify-content: center;
  max-height: 100px;
  width: 100%;
  overflow: hidden;

  @include large-screen {
    max-height: 129px;
  }
}

.personnalisationStep__image {
  width: 100%;
  object-fit: cover;
}

.personnalisationStep__title {
  max-width: 375px;
  margin: 16px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  color: $color-title;
  text-align: center;
}

.personnalisationStep__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin: 16px 16px 32px 16px;

  @include large-screen {
    margin: 16px 32px 32px 32px;
  }
}

.personnalisationStep__description {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: justify;
  font-size: 16px;
  line-height: 20px;
}

.personnalisationStep__checkboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &__link {
    color: var(--color-sa-action);
    font-weight: 400;
    text-decoration: none;
  }
}

.personnalisationStep__footer {
  margin-top: auto;
}

.personnalisationStep__footerCTA {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .personnalisationStep__footerCTA__spinner {
    width: 20px;
  }
}

.personnalisationStep__footerCTA-abandon {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  max-height: 100px;
  font-size: 14px;
}

.sending {
  padding-inline: 32px;
}
