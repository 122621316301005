@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.mainContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000040;
  backdrop-filter: blur(2px);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Barlow', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}

.notAuthContainer {
  max-width: $width-base;
  width: 100%;
  max-height: $height-base;
  height: 100dvh;
  margin-inline: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-sa-info);
  background-color: white;
  border-radius: 16px;

  @include large-screen {
    max-height: $height-large-screen;
    margin-inline: auto;
  }
}

.closeIconContainer {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 18px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $color-background-light;
  z-index: 25;
  cursor: pointer;

  @include large-screen {
    top: 22px;
    right: 22px;
  }
}
