@import "../../../globals/styles/_media-queries.scss";@import "../../../globals/styles/_variables.scss";.input {
  margin-bottom: 32px;
  width: 100%;
}

.input__input {
  appearance: none;
  height: 52px;
  width: 100%;
  padding: 0 16px;
  border: 1px solid var(--color-sa-info);
  border-radius: 10px;
  font-family: 'Barlow', sans-serif;
  background-color: $white;
  outline: none;

  &::placeholder {
    color: $grey;
    font-size: 16px;
  }
}
