@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.recommendationCardContainer {
  height: 100%;
  padding: 0 4px 10px 4px;
}

.recommendationCard {
  max-width: 80vw;
  width: 320px;
  height: 100%;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  border: 2px solid var(--color-sa-action-light);
  border-radius: 20px;
  overflow: hidden;
  opacity: 0;
  transition: 0.2s;

  &.recommendationCard--visible {
    opacity: 1;
  }
  // width: 100%;
}

.recommendationCard--fullWidth {
  max-width: 386px;
  width: 100%;
  margin: 0 auto;
}

.recommendationCard__body {
  width: 100%;
  padding: 16px 16px 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  background-color: $white;
}

.recommendationCard__visual {
  width: 125px;
  height: 125px;
}

.recommendationCard__visual-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recommendationCard__content {
  width: 100%;
  margin-block: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  user-select: none;
}

.recommendationCard__title {
  color: $text-main;
}

.recommendationCard__brand {
  font-size: 12px;
  line-height: 14px;
  color: $text-light;
}

.recommendationCard__description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 14px;
  color: $text-light;
}

.recommendationCard__descriptionExpanded {
  line-clamp: unset;
  -webkit-line-clamp: unset;
}

.recommendationCard__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto 0 16px 0;
}

.recommendationCard__noPriceAlignement {
  justify-content: flex-end;
}

.recommendationCard__finalPrice {
  font-family: 'Mulish', sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: $text-main;
}

.recommendationCard__originalPrice {
  font-family: 'Mulish', sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: $text-light;
  text-decoration: line-through;
}

.recommendationCard__button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 133px;
  margin: 0;
  padding: 12px 0;
  text-transform: none;
  cursor: pointer;

  .recommendationCard__button__addIcon {
    path {
      fill: var(--color-sa-action);
    }
  }

  &:hover .recommendationCard__button__addIcon {
    path {
      fill: $white;
    }
  }
}

.recommendationCard__buttonText {
  font-size: 14px;
  line-height: 17px;
}

.recommendationCard__buttonText-default {
  .recommendationCard--active & {
    display: none;
  }
}

.recommendationCard__buttonText-active {
  display: none;

  .recommendationCard--active & {
    display: block;
  }
}

.recommendationCard__seeMoreButton {
  background-color: transparent;
  border: none;
  width: fit-content;
  cursor: pointer;
  color: var(--color-sa-action);
  padding: 0;
  text-decoration: underline;
  font-size: 12px;
}
