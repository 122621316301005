@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.scoreCard {
  &__container {
    height: 100%;
    width: $width-diagnosis-card-mobile;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 8px;
    background-color: $white;
    border: 1px solid var(--color-sa-action-light);
    border-radius: 32px;

    @include large-screen {
      width: $width-diagnosis-card-large-screen;
    }
  }

  &__summaryContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 67px;
    width: 100%;
    padding: 16px 24px;
  }

  &__name {
    font-size: 14px;
  }

  &__ratingContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__rating {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;
    height: 35px;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    color: $white;
    border-radius: 20px;
  }

  &__pictureContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 320px;
    overflow: hidden;

    @include large-screen {
      align-items: unset;
    }
  }

  &__picture {
    width: 100%;
    max-width: 240px;
    object-fit: cover;

    @include large-screen {
      width: unset;
      max-width: unset;
    }
  }

  &__textContainer {
    padding: 16px;
  }

  &__text {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    line-height: 17px;

    &--expanded {
      -webkit-line-clamp: initial;
      line-clamp: initial;
      -webkit-box-orient: initial;
      overflow: initial;
    }
  }

  &__seeMoreButton {
    align-self: flex-start;
    width: fit-content;
    margin-top: 10px;
    padding: 0;
    border: none;
    background-color: transparent;
    color: var(--color-sa-action);
    cursor: pointer;
  }
}

.excellent {
  background-color: $color-score-excellent;
}

.veryGood {
  background-color: $color-score-very-good;
}

.good {
  background-color: $color-score-good;
}

.average {
  background-color: $color-score-average;
}

.bad {
  background-color: $color-score-bad;
}

.veryBad {
  background-color: $color-score-very-bad;
}

.excellent--text {
  color: $color-score-excellent;
}

.veryGood--text {
  color: $color-score-very-good;
}

.good--text {
  color: $color-score-good;
}

.average--text {
  color: $color-score-average;
}

.bad--text {
  color: $color-score-bad;
}

.veryBad--text {
  color: $color-score-very-bad;
}
