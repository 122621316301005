@import "../../../../globals/styles/_media-queries.scss";@import "../../../../globals/styles/_variables.scss";.homeStep {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  max-height: $height-base;

  @include large-screen {
    max-height: $height-large-screen;
  }
}

.homeStep__visual {
  position: relative;
  display: flex;
  justify-content: center;
  height: 42dvw;
  max-height: 100px;
  width: 100%;
  overflow: hidden;

  @include large-screen {
    max-height: 129px;
  }
}

.homeStep__visual-image {
  width: 100%;
  object-fit: cover;
}

.homeStep__content {
  padding: 32px 16px;
  display: flex;
  flex-direction: column;

  @include large-screen {
    padding: 24px;
  }
}

.homeStep__title {
  margin-bottom: 10px;
}

.homeStep__subtitle {
  color: var(--color-sa-info);
  text-align: center;
}

.homeStep__description {
  margin: 24px 0 16px;
}

.homeStep__consent {
  display: flex;
  align-items: center;
  margin-top: 16px;

  &__link {
    color: var(--color-sa-action);
    font-weight: 400;
    text-decoration: none;
  }
}

.homeStep__button {
  margin-bottom: 32px;
}

.inline_text {
  display: inline;
}
